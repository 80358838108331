@import 'styles/_mixins.scss';

#filters {
  @include border();
  margin-block: 24px;
  border-radius: 2px;
  padding-block: 16px;
  padding-inline: 20px;

  .inputsGroup {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;

    .input {
      h5 {
        color: #9d9696;
        font-size: var(--fs-12);
      }
    }

    &:first-of-type .input {
      width: 100%;
    }
  }

  .controlButtons {
    display: flex;
    gap: 20px;
  }
  @media (max-width: 600px) {
    div {
      width: 100%;
    }
  }
}
