@import "styles/colors";
@import "styles/_mixins.scss";

#navbar {
    background-color: $clr-mainBlue;
    padding-block: 4px;
    z-index: 1000;
    position: fixed;
    width: 100%;
    .content {
        @include flex(center);

        .brandLogo img {
            width: 6.250vw;

            @media (max-width:600px) {
                width: 50% !important;
            }
        }

        .data {
            @include flex(center);
            justify-content: flex-end;

            div,
            h5 {
                flex: unset;
            }

            h5 {
                padding-inline: 12px;
                color: $clr-light;
                font-size: var(--fs-12);
            }

            .languageBtn {
                background: transparent;
                border: 0;
            }
        }

        @media (max-width:600px) {
            flex-direction: row !important;
        }
    }
}