@font-face {
    font-family: "GBook";
    src: url("./gotham/GothamBook.ttf");
}

@font-face {
    font-family: "GMedium";
    src: url("./gotham/GothamMedium.ttf");
}

@font-face {
    font-family: "CBook";
    src: url("./cairo/Cairo-Regular.ttf");
}

@font-face {
    font-family: "CMedium";
    src: url("./cairo/Cairo-Medium.ttf");
}