#main {
    padding-top: 30vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    // align-items: center;

    .brandLogo {
        margin-bottom: 50px;
        text-align: center;
    }

    form .inputContainer {
        margin-block: 30px;

        input,
        .MuiFormControl-root {
            width: 300px;
            @media (max-width:600px) {
                width: 100%;
            }
        }
    }
}