@import '../../../_colors.scss';
@import '../../../_mixins.scss';

#merchant {
  .header {
    box-shadow: 0px 3px 16px #0000000a;

    header {
      display: flex;
      align-items: center;
      padding-block: 23px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        margin-inline-end: 15px;
      }

      h3 {
        font-size: var(--fs-12);
        color: var(--clr-dark);
      }

      h5 {
        font-size: var(--fs-8);
        color: #b7b7c1;
      }
    }
  }

  .cards {
    @include flex(flex-end);
    margin-block: 30px;

    .card {
      flex: 0.24;
      width: 100%;
      background: #f9fafc 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 5px #a8a8a826;
      border-radius: 2px;
      padding-block: 20px;
      padding-inline: 35px;
    }
  }

  .active_status {
    border-radius: 8px;
    display: inline-block;
    width: 90px;
    padding-block: 2px;
    background-color: $clr-lightGreen;

    h5 {
      font-size: var(--fs-8);
      text-align: center;
      color: $clr-green;
    }
  }

  .inactive_status {
    border-radius: 8px;
    display: inline-block;
    width: 90px;
    padding-block: 2px;
    background-color: $clr-lightRed;

    h5 {
      font-size: var(--fs-8);
      text-align: center;
      color: $clr-red;
    }
  }
}

#timeline {
  section.account_activity {
    border: 1px solid #dedede;
    border-radius: 2px;
    padding: 30px;

    header {
      @include flex(center);
      margin-bottom: 50px;
    }
  }
}

#services {
  a {
    color: var(--clr-lightBlue);
  }

  header {
    @include flex(center);
    padding: 15px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      span,
      img {
        margin-inline-start: 20px;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
      margin-inline-end: 21px;
    }
  }
}

#overview {
  @include flex(flex-start);

  .details,
  .documents {
    box-shadow: 0px 3px 13px #77737326;
    margin-inline-end: 10px;
    flex: unset !important;
    width: 49%;
    align-self: stretch;

    header {
      @include flex(center);
      background-color: #f9fafc;
      padding-block: 14px;
      padding-inline: 30px;

      & > * {
        flex: 0.5;
        margin-block: 0px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
      }
    }

    .content {
      padding-block: 14px;
      padding-inline: 30px;
      & > div {
        border-bottom: 1px solid #eaeaea;
        padding-block: 12px;

        &:last-of-type {
          border-bottom: 0;
        }
      }
      overflow: auto;
      max-height: 500px;
    }

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  .details {
    section {
      @include flex(center);
      align-items: flex-start;

      .img {
        width: 60px;
      }

      .value {
        color: #707070;
        word-break: break-all;
      }

      @media (max-width: 600px) {
        flex-direction: row;

        .img {
          width: 0;
        }
      }
    }
  }

  .documents {
    section {
      @include flex(flex-start, row);
      border-bottom: 1px solid #eaeaea;
      padding-block: 12px;

      &:last-of-type {
        border-bottom: 0;
      }

      .value {
        color: #707070;
      }
    }
  }
}

#transaction_Detail {
  header {
    a {
      display: flex;
      align-items: center;
      padding-block: 36px;
      width: fit-content;

      h3 {
        font-size: var(--fs-14);
        color: var(--clr-dark);
      }
    }
  }

  .details {
    border: 1px solid #dedede;
    margin-inline-end: 10px;
    flex: unset !important;
    align-self: stretch;

    header {
      @include flex(center);
      background-color: #f9fafc;
      padding-block: 14px;
      padding-inline: 30px;

      & > * {
        flex: 0.5;
        margin-block: 0px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
      }
    }

    .content {
      padding-block: 14px;
      padding-inline: 30px;
    }

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  .details {
    section {
      @include flex(center);
      padding: 10px;

      .value {
        color: #707070;
      }
    }
  }
}

#services_Detail {
  header {
    a {
      width: fit-content;
      display: flex;
      align-items: center;
      padding-block: 36px;

      h3 {
        font-size: var(--fs-14);
        color: var(--clr-dark);
      }
    }
  }

  .details {
    @include flex(flex-start);

    .content {
      border: 1px solid #dedede;
      padding-block: 14px;
      padding-inline: 30px;
      margin-inline-end: 10px;
      align-self: stretch;

      header {
        @include flex(center);
        // background-color: #F9FAFC;
        padding-block: 14px;
        // padding-inline: 30px;

        & > * {
          flex: 0.5;
          margin-block: 0px;
        }

        @media (max-width: 600px) {
          flex-direction: row;
        }
      }
    }

    @media (max-width: 600px) {
      width: 100% !important;

      .content {
        margin-inline-end: 0px;
      }
    }
  }

  .details {
    section {
      @include flex(center);
      padding: 10px;

      .value {
        color: #707070;
      }

      @media (max-width: 600px) {
        flex-direction: row !important;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
      margin-inline-end: 21px;
    }
  }
}

#request_Details {
  .header {
    box-shadow: 0px 3px 16px #0000000a;

    header {
      display: flex;
      align-items: center;
      padding-block: 23px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        margin-inline-end: 15px;
      }

      h3 {
        font-size: var(--fs-12);
        color: var(--clr-dark);
      }

      h5 {
        font-size: var(--fs-8);
        color: #b7b7c1;
      }
    }
  }

  .details {
    border: 1px solid #dedede;
    margin-inline-end: 10px;
    flex: unset !important;
    align-self: stretch;
    border-radius: 2px;

    header {
      @include flex(center);
      background-color: #f9fafc;
      padding-block: 14px;
      padding-inline: 30px;
      border-radius: 4px;

      & > * {
        flex: 0.5;
        margin-block: 0px;
      }

      @media (max-width: 600px) {
        flex-direction: row;
      }
    }

    .content {
      padding-block: 14px;
      padding-inline: 30px;
    }

    section {
      @include flex(center);
      padding: 10px;
      align-items: flex-start;

      .value {
        color: #707070;
        word-break: break-all;
        width: 90%;
      }
    }

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}
:global(.MuiGrid-container) {
  .value {
    color: #707070;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
