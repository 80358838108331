@import 'styles/_mixins.scss';

#filters {
  @include flex(flex-start);
  @include border();
  margin-block: 24px;
  border-radius: 2px;
  padding-block: 16px;
  padding-inline: 20px;

  .search {
    h5 {
      color: #9d9696;
      font-size: var(--fs-12);
    }
  }

  .filter {
    margin-top: auto;
  }

  @media (max-width: 600px) {
    div {
      width: 100%;
    }
  }
}
