@import 'styles/_mixins.scss';

.container {
  margin-block: 20px;
  border: 1px solid #dedede;
  border-radius: 1px;
  @include flex(flex-start);

  .drop_file {
    margin: 10px;
    border: 1px dashed #dedede;
    border-radius: 1px;
    height: 167px;
    background-color: #fafafa;
    @include flex(center);
    flex-direction: column;
    justify-content: center;

    label {
      flex: unset;
      text-align: center;

      h5 {
        margin-block: 5px;
      }

      button {
        width: 90%;
        min-width: 0 !important;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
      }
    }
  }

  .lists {
    margin: 10px;
    flex: 1.2;

    span {
      color: #9f9d9d;
    }
  }

  .filter_container {
    padding-block: 10px;
    border-bottom: 0.5px solid #d0d0d0;

    h5 {
      color: #707070;
    }

    progress {
      width: 100%;
      height: 6px;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}
.hide {
  display: 'none';
}
