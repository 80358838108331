@import 'styles/_mixins.scss';

.btn {
  cursor: pointer;
  color: var(--clr-lightBlue);
}

#drawer {
  header {
    @include flex(center);
    padding-block: 10px;
    padding-inline: 40px;
    background: #f9fafc;

    div {
      display: flex;
    }
  }

  section.content {
    padding-block: 10px;
    padding-inline: 20px;

    .upload-img {
      margin: 10px;
      img {
        flex: unset;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        overflow: hidden;
        margin-inline-end: 15px;
      }
    }

    .row {
      @include flex(center);

      & > div {
        margin: 10px;
        @media (max-width: 600px) {
          margin-inline: 0 !important;
        }
      }

      div {
        width: 100%;
      }
    }

    .submitBtn {
      margin-top: 20px;
      justify-content: center;

      button {
        flex: 0.5;
      }
    }

    hr {
      opacity: 0.5;
      border-color: #e5e5e5;
      margin-inline: 10px;
      margin-block: 20px;
    }
  }
}
