$clr-mainBlue: #01338d;
$clr-lightBlue: #1782fb;

$clr-borderColor: #dedede;

$clr-green: #28895c;
$clr-lightGreen: #c3ebd8;

$clr-red: #b72020;
$clr-lightRed: #ebc3c3;

$clr-yellow: #d38b03;
$clr-lightYellow: #ffebc4;

$clr-gray: #707070;
$clr-light: #fff;
$clr-dark: #000000;

$clr-lightIndigo: #e8d6f5;
$clr-indigo: #48007c;
