@import './_colors.scss';
@import 'assets/fonts/index.css';
// @import "./datepicker.scss";
@import 'antd/dist/reset.css';

:root {
  --ff-GBook: 'GBook';
  --ff-GMedium: 'GMedium';

  --ff-CBook: 'CBook';
  --ff-CMedium: 'CMedium';

  /* font sizes */
  --fs-8: clamp(8px, 0.625vw, 50px);
  --fs-10: clamp(10px, 0.781vw, 50px);
  --fs-11: clamp(11px, 0.859vw, 50px);
  --fs-12: clamp(12px, 0.938vw, 50px);
  --fs-14: clamp(14px, 1.094vw, 50px);
  --fs-18: 18px;

  --clr-mainBlue: #{$clr-mainBlue};
  --clr-lightBlue: #{$clr-lightBlue};
  --clr-borderColor: #{$clr-borderColor};
  --clr-green: #{$clr-green};
  --clr-lightGreen: #{$clr-lightGreen};
  --clr-red: #{$clr-red};
  --clr-lightRed: #{$clr-lightRed};
  --clr-yellow: #{$clr-yellow};
  --clr-lightYellow: #{$clr-lightYellow};
  --clr-gray: #{$clr-gray};
  --clr-light: #{$clr-light};
  --clr-dark: #{$clr-dark};
  --clr-lightIndigo: #{$clr-lightIndigo};
  --clr-indigo: #{$clr-indigo};
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #e6e6e6;
}

*::-webkit-scrollbar-thumb {
  background: $clr-gray;
}

html,
body {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  background: $clr-light;
  font-family: var(--ff-GBook), -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--clr-lightBlue);
  display: inline-block;
  text-decoration: none;
}

.status {
  &--active,
  &--active--alternative,
  &--inactive,
  &--pending,
  &--pending--yellow,
  &--updated {
    border-radius: 18px;
    display: inline-block;
    min-width: 90px;
    padding-block: 5px;
    padding-inline: 13px;
    text-align: center;
  }

  &--active {
    background-color: var(--clr-lightGreen);
    color: var(--clr-green);
  }

  &--active--alternative {
    border: 1px solid var(--clr-lightGreen);
    color: var(--clr-green);
    border-radius: 3px;
  }

  &--inactive {
    background-color: var(--clr-lightRed);
    color: var(--clr-red);
  }

  &--pending {
    border: 1px solid #d3d3d3;
    background: #f4f4f4;
    color: #b7b6b6;
  }

  &--pending--yellow {
    background: var(--clr-lightYellow);
    color: var(--clr-yellow);
  }
  &--updated {
    background: var(--clr-lightIndigo);
    color: var(--clr-indigo);
  }
}

body[dir='rtl'] .MuiPaginationItem-previousNext {
  transform: rotate(180deg);
}

button {
  cursor: pointer;
}

.details_arrow {
  width: 14px;
  margin-inline-end: 10px;
}

body[dir='rtl'] {
  .details_arrow {
    transform: rotate(180deg);
  }
}

.ant-select-selection-item,
.ant-input,
.ant-select-selection-search-input {
  font-family: var(--ff-GBook);
}

.ant-input-group-addon {
  border-radius: 0 !important;
  border-inline: none !important;
  background: transparent !important;
}
