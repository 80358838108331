.activity_card {
    display: flex;
    align-items: center;
    h4{
        text-transform: capitalize;
    }

    .state {
        margin-inline-end: 30px;
        width: 8px;
        height: 8px;
        background-color: var(--clr-lightBlue);
        border-radius: 10px;
    }

    .content {
        border-bottom: 1px solid #DEDEDE;
        padding-block: 21px;
        flex: .95;
        h5 {
            color: #707070;
        }
    }
}